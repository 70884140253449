<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <div class="vx-row">
        <div class="vx-col xs:w-full lg:w-3/4">
          <div class="flex flex-wrap items-center">
            <vs-button @click="addUser" class="mr-2 sm:mb-0">Add</vs-button>
            <!-- <vx-tooltip text="Download excel template for bulk insert" position="bottom">
              <vs-button @click="handleTemplateDownload" color="primary" class="mr-2 sm:mb-0" type="border" icon-pack="feather" icon="icon-download"></vs-button>
            </vx-tooltip>
            <input type="file" hidden id="excelUploadInput" ref="excelUploadInput" v-on:change="handleExcelUpload"/>
            <vx-tooltip text="Upload excel file, to bulk insert" position="bottom">
              <vs-button @click="$refs.excelUploadInput.click()" color="primary" class="mr-2 sm:mb-0" type="border" icon-pack="feather" icon="icon-upload">
                <span v-if="!isSmallerScreen">Upload Excel</span>
              </vs-button>
            </vx-tooltip>
            
            <vx-tooltip text="Refresh data" position="bottom">
              <vs-button @click="handleRefresh" color="primary" class="mr-2 sm:mb-0" type="border" icon-pack="feather" icon="icon-refresh-ccw"></vs-button>
            </vx-tooltip> -->
          </div>
        </div>
        <div class="vx-col xs:w-full lg:w-1/4">
          <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" 
          v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
        </div>
      </div>
      
      <ag-grid-vue v-if="usersData"
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 dynamic-height-user-grid ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        :gridOptions="gridOptions"
        rowSelection="single"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="5"
        v-model="currentPage" />
    </div>
    <vs-prompt
      title="Bulk Insert Results"
      buttons-hidden
      @cancel="clearBulkInsertPrompt"
      @close="clearBulkInsertPrompt"
      :active.sync="bulkInsert.showBulkInsertPrompt">
      <div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <p>{{'Successfully added ' + bulkInsert.successfulInserts + ' user(s).' }}</p>
          </div>

          <div v-if="bulkInsert.missingRoleCount" class="vx-col w-full mt-4">
            <p>{{'There are ' + bulkInsert.missingRoleCount + ' user(s) with unspecified roles, select a role from the drop-down to set roles for all these users.' }}</p>
          </div>
        </div>

        <vs-button v-if="bulkInsert.missingRoleCount" @click="clearBulkInsertPrompt" color="danger" type="flat" class="ml-auto mt-6">Cancel</vs-button>
        <vs-button v-if="bulkInsert.missingRoleCount" @click="fillInMissingRoles" class="float-right ml-auto mt-6">Fill in missing roles</vs-button>
        <vs-button @click="clearBulkInsertPrompt" class="ml-auto mt-6">OK</vs-button>
    
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import { formatISO } from 'date-fns'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererUserLink from './cell-renderers/CellRendererUserLink.vue'
import CellRendererUserCheckbox from './cell-renderers/CellRendererUserCheckbox.vue'
import CellRendererUserActions from './cell-renderers/CellRendererUserActions.vue'
import userStore from '@/app/company-users/userStore.js'
import Vue from "vue"
import vSelect from 'vue-select'
import fileDownload from 'js-file-download';

export default {
  components: {
    AgGridVue,
    CellRendererUserLink,
    CellRendererUserCheckbox,
    CellRendererUserActions,
    vSelect
  },
  props: { 
    companyId: { required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      rowData: null,
      cellRendererComponents: {
        CellRendererUserLink,
        CellRendererUserCheckbox,
        CellRendererUserActions,
      },
      searchQuery: '',
      bulkInsert: {
        showBulkInsertPrompt: false,
        successfulInserts: 0,
        missingRoleCount: 0,
      },
      roleOptions: [
        { label: 'Admin', code: 1 },
        { label: 'Editor', code: 2 },
      ]
    }
  },
  computed: {
    usersData () {
      return this.$store.state.userStore.companyUsers
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    if (!userStore.isRegistered) {
      this.$store.registerModule('userStore', userStore)
      userStore.isRegistered = true
    }
    
  },
  beforeMount() {
    this.$store.dispatch('userStore/fetchUsers', this.companyId )
      .then(response => {
        
      })
      .catch(err => console.error(err) )
    
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'userId', hide: true },
      { headerName: 'Actions', width: 120, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererUserActions) },
      // Supppress size to fit excludes column from auto resize if gridApi.sizeColumnsToFit() is called
      { headerName: 'Full Name', field: 'fullName', sortable: true, suppressSizeToFit: true, width: 250, cellRendererFramework: Vue.extend(CellRendererUserLink) },
      { headerName: 'Email', field: 'email', sortable: true, suppressSizeToFit: true, width: 250 },
      { headerName: 'Role Title', field: 'roleTitle', sortable: true, suppressSizeToFit: true, width: 200 },
      { headerName: 'Is Active', field: 'isActive', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererUserCheckbox) },
      { 
        headerName: 'Last Login',
        field: 'lastLogin',
        sortable: true,
        suppressSizeToFit: true,
        width: 180,
        valueFormatter: (param) => param.value && formatISO( new Date(param.value) , { representation: 'date' }),
        hide: true
      },
      { 
        headerName: 'Created',
        field: 'created',
        sortable: true,
        // suppressSizeToFit: true,
        width: 180, 
        valueFormatter: (param) => param.value && formatISO( new Date(param.value) , { representation: 'date' }),
        hide: true
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    addUser () {
      this.$router.push({ name: 'admin-crm-user-add', params: { companyId: this.companyId, readOnly: false } })
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    handleRefresh () {
      this.$vs.loading();
      this.$store.dispatch('userStore/fetchUsers', this.companyId )
        .catch(err => console.error(err))
        .finally(() => this.$vs.loading.close())
    },
    handleTemplateDownload () {
      this.$vs.loading();
      this.$http.get( 
        `api/companies/${this.companyId}/users/excelTemplate`, 
        { responseType: 'blob'})
        .then(response => {
          fileDownload(response.data, 'AddUsersTemplate.xlsx')
        })
        .catch(err => console.error(err))
        .finally(() => this.$vs.loading.close())
    },
    handleExcelUpload () {
      this.file = this.$refs.excelUploadInput.files[0];
      if (this.file) this.submitExcel();
    },
    submitExcel () {
      let formData = new FormData();
      formData.append('excelFile', this.file);
      this.$vs.loading()
      this.$http.post( `api/companies/${this.companyId}/users/excelUpload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      })
      .then(response => {

        this.bulkInsert.successfulInserts = response.data.successfulInserts
        this.bulkInsert.missingRoleCount = response.data.missingRoles
        const fetchUserAction = this.$store.dispatch('userStore/fetchUsers', this.companyId );
        const saveUserMissingRole = this.$store.dispatch('userStore/saveBulkInsertItems', response.data.usersWithMissingRoles );

        this.$vs.loading();
        Promise.all([fetchUserAction, saveUserMissingRole])
          .then(results => this.bulkInsert.showBulkInsertPrompt = true)
          .catch(error => console.error(error))
          .finally(() => this.$vs.loading.close())
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => { 
        this.$refs.excelUploadInput.value = '';
        this.$vs.loading.close()
      })
    },
    fillInMissingRoles () {
      this.$router.push({ name: 'company-users-bulk-insert'})
    },
    clearBulkInsertPrompt () {
      this.bulkInsert.showBulkInsertPrompt = false
    }
  }
}
</script>

<style scoped>
  .dynamic-height-user-grid {
    height: calc(var(--vh, 1vh) * 100 - 33rem);
  }
</style>