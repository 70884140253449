import axios from "@/axios.js"
import _ from 'lodash'
import { startOfYesterday } from "date-fns"

const state = {
  activeCompanyId: 0
}

const mutations = {
  SET_ACTIVE_COMPANY_ID (state, companyId) {
    state.activeCompanyId = companyId
  }
}

const actions = {}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions
}