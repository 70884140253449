<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button @click="onAdd" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
          <!-- <vs-button @click="deleteRoles" type="border" color="danger" class="mr-4 sm:mb-0 mb-4">Delete</vs-button> -->
          <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table dynamic-height-app-grid"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="applicationData"
        :gridOptions="gridOptions"
        rowSelection="single"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="5"
        v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererAppName from './cell-renderers/CellRendererAppName'
import CellRendererAppActions from './cell-renderers/CellRendererAppActions'
import CellRendererAppCheckbox from './cell-renderers/CellRendererAppCheckbox'
import CellRendererAppLogo from './cell-renderers/CellRendererAppLogo'
import companyApplicationStore from '@/app/company-applications/companyApplicationStore.js'

import Vue from "vue"
import { formatISO } from 'date-fns'


export default {
  components: {
    AgGridVue,
    CellRendererAppName,
    CellRendererAppActions,
    CellRendererAppCheckbox,
    CellRendererAppLogo
  },
  props: { 
    companyId: { required: true }
  },
  data () {
    return {
      dummyData: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererAppName,
        CellRendererAppActions,
        CellRendererAppCheckbox,
        CellRendererAppLogo
      },
      searchQuery: ''
    }
  },
  computed: {
    applicationData () {
      return this.$store.state.companyApplicationStore.applications
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
    }
    this.$store.dispatch('companyApplicationStore/fetchApplications', this.companyId )
      .catch(err => console.error(err) )
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'applicationId', hide: true },
      { headerName: 'Actions', width: 120, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppActions) },
      { headerName: 'Logo', field: 'logo', width: 100, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppLogo)},
      { headerName: 'Application Name', field: 'applicationName', width: 300, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppName)},
      { headerName: 'Region', field: 'region', width: 150,  suppressSizeToFit: true },
      { headerName: 'Is Active', field: 'isActive', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererAppCheckbox) },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 180,
        valueFormatter: (param) => param.value && formatISO( new Date(param.value) , { representation: 'date' }) 
      },
      { field: 'logoUrl', hide: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onAdd () {
      this.$router.push({ name: 'admin-crm-apps-add', params: { companyId: this.companyId}})
    },

    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {

    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  }
}
</script>

<style scoped>
  .dynamic-height-app-grid {
    height: calc(var(--vh, 1vh) * 100 - 33rem);
  }
</style>