<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="displayRecord" />
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    activeCompanyId() {
      return this.$store.state.hostAdminCrmStore.activeCompanyId;
    }
  },
  methods: {
    editRecord () {
      this.$router.push({ name: 'admin-crm-user-edit', params: { companyId: this.activeCompanyId, userId: this.params.data.userId, readOnly: false } })
        .catch((error) => { console.error(error) })
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Delete user ${this.params.data.email}`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$vs.loading()
      this.$store.dispatch('userStore/removeUser', { 
        userId: this.params.data.userId,
        companyId: this.activeCompanyId
      })
      .then(response => {
        this.$_notifySuccess('Successfully deleted user')
      })
      .catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(() => this.$vs.loading.close())
    },
    displayRecord () {
      this.$router.push({ name: 'admin-crm-user-view', params: { companyId: this.activeCompanyId, userId: this.params.data.userId, readOnly: true } })
        .catch((error) => { console.error(error) })
    },
  }
}
</script>
